import { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { MapPin, Link } from 'lucide-react';
import { location, nextLocation } from '../../config';
import './Me.css';

export const Me = () => {
	const [showHi, setShowHi] = useState(true);
	const [showName, setShowName] = useState(false);

	const locations = [
		"I'm",
		'currently',
		'in',
		<>
			<a href={location.link} target="_blank" rel="noopener noreferrer">
				{location.name} <MapPin />
			</a>
		</>,
		'and',
		'will',
		'head',
		'to',
		<>
			<a
				href={nextLocation.link}
				target="_blank"
				rel="noopener noreferrer">
				{nextLocation.name} <MapPin />
			</a>
		</>,
		'next',
		'!'
	];

	const technologies = [
		'I',
		'make',
		'webapps',
		'using',
		<>
			<a
				href={nextLocation.link}
				target="_blank"
				rel="noopener noreferrer">
				Typescript <Link />
			</a>
		</>,
		'and',
		'mobile',
		'apps',
		'using',
		<>
			<a
				href={nextLocation.link}
				target="_blank"
				rel="noopener noreferrer">
				Flutter <Link />
			</a>
		</>,
		'!'
	];

	const scrollDown = ['Scroll', 'down', 'to', 'see', 'my', 'work'];

	const renderWords = ({ content, delay }) => {
		return content.map((word, index) => (
			<motion.span
				key={index}
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				transition={{ duration: 0.3, delay: delay + index * 0.2 }}>
				{word}
			</motion.span>
		));
	};

	return (
		<section id="me">
			<AnimatePresence>
				{showHi && (
					<motion.h1
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						transition={{ duration: 0.5 }}
						onAnimationComplete={() => {
							setTimeout(() => setShowHi(false), 500);
							setTimeout(() => setShowName(true), 1000);
						}}>
						Hi 🫡
					</motion.h1>
				)}
			</AnimatePresence>

			<AnimatePresence>
				{showName && (
					<motion.h1
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						transition={{ duration: 1 }}>
						I'm Thomas
					</motion.h1>
				)}
				<p>
					{renderWords({
						content: locations,
						delay: 1.5
					})}
				</p>
				<motion.p
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					transition={{ duration: 0.5, delay: 2 }}>
					{renderWords({
						content: technologies,
						delay: 3.5
					})}
				</motion.p>
				<motion.p
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					transition={{ duration: 0.5, delay: 2 }}>
					{renderWords({
						content: scrollDown,
						delay: 6.5
					})}
				</motion.p>
			</AnimatePresence>
		</section>
	);
};
